<template>
    <div class="internal-sections my-click">
        <el-row :gutter="20">
            <el-col :span="18">
                <div class="d-flexk a-i-center">
                    <h3 style="margin-right: 20px">Xodimlar Ro'yxati</h3>
                    <!-- <div class="filter-internal-block uchlik">
                        <el-input
                            class="sorce"
                            prefix-icon="el-icon-search"
                            v-model="input1"
                            size="small"
                        ></el-input>
                        <el-select
                            size="small"
                            v-model="region"
                            placeholder="Filial"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                        <el-select
                            size="small"
                            v-model="region"
                            placeholder="Mutaxassislik"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </div> -->
                </div>
            </el-col>

            <el-col :span="6" class="filter_table">
                <el-button
                    icon="el-icon-circle-plus-outline"
                    class="asosy-btn-d mt10"
                    @click="modalCreate = true"
                >
                    Qo'shish
                </el-button>
                <el-dropdown class="setting-cheek ml-2">
                    <el-button size="small" icon="el-icon-setting"></el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="(column, index) in columns"
                            :key="index"
                        >
                            <el-checkbox
                                :checked="column.show"
                                @change="column.show = !column.show"
                                >{{ column.title }}</el-checkbox
                            >
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
        
        <el-row :gutter="20" v-loading="loadingData">
            <el-col v-for="(item, index) in filteredDoctors"
                    :key="index"  :xs="12" :sm="12" :md="8" :lg="8" :xl="6">
                    <router-link :to="{ name: 'doctorCardInfo', params: {id: item.id} }"
                    > <div  class="card item-doctor new-item-doctor">
                        <div class="item-doctor-img">
                            <img
                                v-if="item.image['id']"
                                class="img-avatar"
                                :src="url + '/' + item.image.path"
                            />
                            <img
                                v-else
                                class="img-avatar"
                                src="./../../../public/img/avatar_doctor.png"
                            />
                        </div>
                        <span class="job">
                            <p class="leff"><i class="el-icon-user"></i> I.F.O:</p>
                            <p> 
                                {{
                                item.name ? item.name : ' ' 
                                }}
                                {{
                                    item.surname ? item.surname  : ' '
                                }}
                                {{
                                    item.lastname ? item.lastname : ' '
                                }}
                            
                            </p>
                        </span>
                        <span class="job">
                            <p  class="leff"><i class="el-icon-office-building"></i> Muassasalar:</p>
                            <p>  {{
                                    item.clinic
                                        ? item.clinic.legal_entity_name
                                        : ""
                                }} 
                            </p>
                        </span>
                        <span class="job">
                            <p  class="leff"><i class="el-icon-suitcase"></i>Mutaxassislik:</p>
                            <p> {{ item.typesOfEmployee ? item.typesOfEmployee.name : "" }} </p>
                        
                        </span>
                        <span class="job">
                            <p class="leff"><i class="el-icon-medal"></i>Turi:</p>
                            <p> {{
                                item.direction ? item.direction.name : '' 
                            }} </p>
                        </span>
                    </div>
                </router-link> 
                
            </el-col>
        </el-row>

        <div class="my-pagination">
            <crm-pagination
                @c-change="updatePagination"
                :pagination="pagination"
            ></crm-pagination>
        </div>

        <!-- Modals -->
        <div class="my-modal">
            <el-drawer
                size="95%"
                title="I am the title"
                :visible.sync="modalCreate"
                :with-header="false"
                :append-to-body="true"
                custom-class="my-modal-khan"
            >
                <MedCreate></MedCreate>
            </el-drawer>
        </div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import MedCreate from "./components/create";
import list from "@/utils/mixins/models/listForModels";
import doctor from '../../utils/mixins/models/doctor';
export default {
    mixins: [list],
    components: {
        MedCreate,
    },
    computed:{
          ...mapGetters({
            list: "doctors/list",
            columns: "doctors/columns",
            pagination: "doctors/pagination",
            filter: "doctors/filter",
            sort: "doctors/sort",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
        filteredDoctors: function () {
            let doctors = [];
            let logo = {};
            if (this.list && !_.isEmpty(this.list)) {
                this.list.forEach((item, index) => {
                    if (item.hasOwnProperty('images') && !_.isEmpty(item.images)) {
                        logo['id'] = item.images[0].id;
                        logo['path'] = item.images[0].path;
                    }else{
                        logo['id'] = null;
                        logo['path'] = null; 
                    }

                    doctors.push({
                        id: item.id,
                        clinic: item.clinic,
                        filial: item.filial,
                        name: item.name,
                        surname: item.surname,
                        lastname: item.lastname,
                        typesOfEmployee: item.typesOfEmployee,
                        region: item.region,
                        city_district: item.city_district,
                        neighborhood: item.neighborhood,
                        home: item.home,
                        apartment_number: item.apartment_number,
                        image: logo 
                    });

                    logo = {};
                });
                return doctors;
            }
            return doctors;
        }
    },
    data() {
        return {
            modalCreate: false,
            filterForm: {},
            url: process.env.VUE_APP_URL_DOCS,
        };
    },
    methods: {
        ...mapActions({
            updateList: "doctors/filteredDoctorsByClinic",
            setPagination: "doctors/setPagination",
            updateSort: "doctors/updateSort",
            updateFilter: "doctors/updateFilter",
            updateColumn: "doctors/updateColumn",
            updatePagination: "doctors/updatePagination",
            show: "doctors/show",
            empty: "doctors/empty",
            delete: "doctors/destroy",
            refreshData: "doctors/refreshData",
        }),
          fetchData() { 
            this.doctors = [];
            if (this.$route.params.hasOwnProperty('id')) {
                let path = this.$route.path.substring(1,13);
                this.model_id = (path === 'officeClinic') ? parseFloat(this.$route.params.id) : null;
            }else{
                this.model_id = null;
            }
            
            const query = {
                ...this.filter,
                ...this.pagination,
                ...this.sort,
                'model_id': this.model_id
            };
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false;
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("doctors/EMPTY_LIST");
        next();
    },
};
</script>
<style lang="scss">
.mmi1.my-doctor-tabs .el-tabs__item {
    margin-bottom: 10px;
    font-size: 22px;
    background: var(--card-color);
    border: none !important;
    background-color: #0087af !important;
    margin: 0px 5px;
    border-radius: 4px !important;
    color: #fff !important;
}
.mmi1.my-doctor-tabs .el-tabs__nav-wrap {
    margin-top: -57px;
}
</style>
