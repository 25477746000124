<template>
    <div>
        <div class="modal-header">
            <h4>Title Modal</h4>
            <div class="modal-title-header">
                <el-button type="primary" plain>Saqlash</el-button>
                <el-button type="success" plain> Yopish </el-button>
            </div>
        </div>

        <div class="modal-content">
            <div class="card-body p30">
                <div class="steps">
                    <ul class="steps-ul">
                        <li v-for="(item, itemIndex) in steps" :key="itemIndex"
                            :class="{ active: item.step === currentStep }"
                            @click="setStep(item.step)"
                        >
                            {{ item.stepText }}
                        </li>
                    </ul>
                </div>

                <div class="steps-body">
                    <div v-if="currentStep === 1" class="step-itme-1">
                        <el-form ref="form" :model="form" class="my-form">
                            <el-row :gutter="5" class="mb20">
                                <el-col :span="10">
                                    <el-form-item
                                        label="Pasport ID"
                                        class="icons-input"
                                    >
                                        <el-input
                                            placeholder="Pasport ID Qidirish ........"
                                            v-model="input1"
                                        >
                                            <template slot="prepend">
                                                <i class="el-icon-search"></i>
                                            </template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3">
                                    <el-button style="margin-top: 29px">
                                        Qidirish
                                    </el-button>
                                </el-col>

                                <el-col :span="11">
                                    <div class="qr">
                                        <img
                                            src="./../../../../public/img/qk.png"
                                        />
                                        <div>000 000 404</div>
                                    </div>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label=" Klinika ">
                                        <el-select
                                            v-model="form.r1"
                                            placeholder=" Klinika "
                                        >
                                            <el-option
                                                label="Erkak"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Ayol"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Filial">
                                        <el-select
                                            v-model="form.r2"
                                            placeholder="Filial"
                                        >
                                            <el-option
                                                label="Erkak"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Ayol"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-divider content-position="left">
                                    Shaxsiy ma'lumotlar
                                </el-divider>

                                <el-col :span="8">
                                    <el-form-item label="Ism">
                                        <el-input
                                            v-model="form.e1"
                                            placeholder="Ism"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Familiya">
                                        <el-input
                                            v-model="form.e2"
                                            placeholder="Familiya"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Otasining ismi">
                                        <el-input
                                            v-model="form.e3"
                                            placeholder="Otasining ismi"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="ID">
                                        <el-input
                                            v-model="form.e4"
                                            placeholder="ID"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item
                                        label="Pasport seriyasi va raqami"
                                    >
                                        <el-input
                                            v-model="form.e5"
                                            placeholder="Pasport seriyasi va raqami"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Tug'ilgan sana">
                                        <el-date-picker
                                            type="date"
                                            placeholder="Tug'ilgan sana"
                                            v-model="form.date1"
                                            style="width: 100%"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="STIR">
                                        <el-input
                                            v-model="form.e6"
                                            placeholder="STIR"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="INN">
                                        <el-input
                                            v-model="form.e7"
                                            placeholder="INN"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item
                                        label="Elektron pochta manzili"
                                    >
                                        <el-input
                                            v-model="form.e7"
                                            placeholder="Elektron pochta manzili"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Ish telefoni raqami">
                                        <el-input
                                            v-model="form.e8"
                                            placeholder="Ish telefoni raqami"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Mobil telefoni raqami">
                                        <el-input
                                            v-model="form.e9"
                                            placeholder="Mobil telefoni raqami"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Jinsi">
                                        <el-select
                                            v-model="form.r4"
                                            placeholder="Jinsi"
                                        >
                                            <el-option
                                                label="Erkak"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Ayol"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Millati">
                                        <el-select
                                            v-model="form.r5"
                                            placeholder="Millati"
                                        >
                                            <el-option
                                                label="O'zbek"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Rus"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Tug'ilgan joyi">
                                        <el-input
                                            v-model="form.q1"
                                            placeholder="Tug'ilgan joyi"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-col :span="24" class="mt20">
                                    <el-divider content-position="left">
                                        Yashash manzil
                                    </el-divider>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Viloyat">
                                        <el-select
                                            v-model="form.q7"
                                            placeholder="Viloyat"
                                        >
                                            <el-option
                                                label="Farg'ona"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Toshkent"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Shahar / Tuman">
                                        <el-select
                                            v-model="form.q8"
                                            placeholder="Shahar / Tuman"
                                        >
                                            <el-option
                                                label="Farg'ona"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Toshkent"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Mahalla">
                                        <el-input
                                            v-model="form.q0"
                                            placeholder="Mahalla"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Uy">
                                        <el-input
                                            v-model="form.q34"
                                            placeholder="Uy"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Xonadon">
                                        <el-input
                                            v-model="form.q4"
                                            placeholder="Xonadon"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="24">
                                    <el-form-item label="Xodimning rasmi">
                                        <el-upload
                                            action="#"
                                            list-type="picture-card"
                                            :auto-upload="false"
                                        >
                                            <i
                                                slot="default"
                                                class="el-icon-plus"
                                            ></i>
                                            <div
                                                slot="file"
                                                slot-scope="{ file }"
                                            >
                                                <img
                                                    class="
                                                        el-upload-list__item-thumbnail
                                                    "
                                                    :src="file.url"
                                                    alt=""
                                                />
                                                <span
                                                    class="
                                                        el-upload-list__item-actions
                                                    "
                                                >
                                                    <span
                                                        class="
                                                            el-upload-list__item-preview
                                                        "
                                                        @click="
                                                            handlePictureCardPreview(
                                                                file
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="
                                                                el-icon-zoom-in
                                                            "
                                                        ></i>
                                                    </span>
                                                    <span
                                                        v-if="!disabled"
                                                        class="
                                                            el-upload-list__item-delete
                                                        "
                                                        @click="
                                                            handleDownload(file)
                                                        "
                                                    >
                                                        <i
                                                            class="
                                                                el-icon-download
                                                            "
                                                        ></i>
                                                    </span>
                                                    <span
                                                        v-if="!disabled"
                                                        class="
                                                            el-upload-list__item-delete
                                                        "
                                                        @click="
                                                            handleRemove(file)
                                                        "
                                                    >
                                                        <i
                                                            class="
                                                                el-icon-delete
                                                            "
                                                        ></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20" class="mb20">
                                <el-col :span="12">
                                    <el-form-item
                                        label="Xodimlar turlari"
                                        class="icons-input"
                                    >
                                        <el-select
                                            v-model="form.q6"
                                            placeholder="Xodimlar Turlarii"
                                        >
                                            <el-option
                                                label="Bosh shifokor"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="Doctor"
                                                value="beijing"
                                            ></el-option>
                                            <el-option
                                                label="Xisobchi"
                                                value="beijing2"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Xodimlar turlari">
                                        <el-checkbox-group v-model="form.q66">
                                            <el-checkbox
                                                label="Xodimlar turlari"
                                                name="type"
                                            >
                                            </el-checkbox>
                                            <el-checkbox
                                                label="Shifokor"
                                                name="type"
                                            >
                                            </el-checkbox>
                                            <el-checkbox
                                                label="Xodimlar"
                                                name="type"
                                            >
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>

                    <div v-if="currentStep === 2" class="step-itme-2">
                        <el-form ref="form" :model="form" class="my-form">
                            <el-row :gutter="20">
                                <el-divider content-position="left">
                                    Shaxsiy ma'lumotlar
                                </el-divider>

                                <el-col :span="8">
                                    <el-form-item
                                        label="Oliy o'quv yurti"
                                        class="icons-input"
                                    >
                                        <el-select
                                            v-model="form.a1"
                                            placeholder="Oliy o'quv yurti"
                                        >
                                            <el-option
                                                label="Sanpi"
                                                value="shanghai"
                                            ></el-option>
                                            <el-option
                                                label="AH TATU"
                                                value="beijing"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Mutaxassislik">
                                        <el-input
                                            v-model="form.a2"
                                            placeholder="Mutaxassislik"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Qachon boshlangan">
                                        <el-date-picker
                                            v-model="form.a3"
                                            type="month"
                                            placeholder="Qachon boshlangan"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Diplom seriya raqami">
                                        <el-input
                                            v-model="form.a4"
                                            placeholder="Diplom seriya raqami"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Ilova seriya raqami">
                                        <el-input
                                            v-model="form.a6"
                                            placeholder="Ilova seriya raqami"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="24">
                                    <div class="add-form-educet">
                                        <el-button
                                            class="asosy-btn-d"
                                            icon="el-icon-plus"
                                        >
                                            Qo'shish
                                        </el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>

                    <div v-if="currentStep === 3" class="step-itme-3">
                        <el-form ref="form" :model="form" class="my-form">
                            <div class="form-tabs-doctor-info">
                                <el-tabs type="border-card">
                                    <el-tab-pane label="Ish Tajribasi">
                                        <el-row :gutter="20">
                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ish Tajribasi "
                                                    class="icons-input"
                                                >
                                                    <el-select
                                                        v-model="form.t1"
                                                        placeholder="Ish Tajribasi"
                                                    >
                                                        <el-option
                                                            label="HA"
                                                            value="shanghai"
                                                        ></el-option>
                                                        <el-option
                                                            label="Yoq"
                                                            value="beijing"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Qachon tugatgan"
                                                >
                                                    <el-date-picker
                                                        v-model="form.t11"
                                                        type="month"
                                                        placeholder="Qachon tugatgan"
                                                    >
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Necha yil "
                                                    class="icons-input"
                                                >
                                                    <el-select
                                                        v-model="form.t2"
                                                        placeholder="Necha yil"
                                                    >
                                                        <el-option
                                                            label="1"
                                                            value="a1"
                                                        ></el-option>
                                                        <el-option
                                                            label="2"
                                                            value="a2"
                                                        ></el-option>
                                                        <el-option
                                                            label="3"
                                                            value="a3"
                                                        ></el-option>
                                                        <el-option
                                                            label="4"
                                                            value="a4"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>

                                        <el-row :gutter="20" class="mt30">
                                            <el-col :span="24">
                                                <el-form-item
                                                    label="Ish Malakasi 5 yildan oshganmi? (Ixtisosligi Malaka toyifasi bormi)"
                                                >
                                                    <el-checkbox-group
                                                        v-model="form.t3"
                                                    >
                                                        <el-checkbox
                                                            label="HA"
                                                            name="type"
                                                        >
                                                        </el-checkbox>
                                                        <el-checkbox
                                                            label="YOQ"
                                                            name="type"
                                                        >
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Malaka Toifasi"
                                                    class="icons-input"
                                                >
                                                    <el-select
                                                        v-model="form.t33"
                                                        placeholder="Malaka Toifasi"
                                                    >
                                                        <el-option
                                                            label="Bilmadim 1"
                                                            value="shanghai"
                                                        ></el-option>
                                                        <el-option
                                                            label="Bilmadim 2"
                                                            value="beijing"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Qaysi Ixtisosligi boyicha malaka toyifasi olgan"
                                                >
                                                    <el-input
                                                        v-model="form.t4"
                                                        placeholder="Qaysi Ixtisosligi boyicha malaka toyifasi olgan"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Malaka toifasi qachon olgan"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Malaka toifasi qachon olgan"
                                                        v-model="form.dat4te1"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Malaka toifasi qanday xujalar asosida berilgan"
                                                >
                                                    <el-input
                                                        v-model="form.t4"
                                                        placeholder="Malaka toifasi qanday xujalar asosida berilgan"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Hujjata seriyasi raqami"
                                                >
                                                    <el-input
                                                        v-model="form.t44"
                                                        placeholder="Hujjata seriyasi raqami "
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Hujjata Sanasi"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Hujjata Sanasi"
                                                        v-model="form.t4tt5"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Hujjata Bergan tashkilot nomi"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Hujjata Bergan tashkilot nomi"
                                                        v-model="form.t5"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Hujjat imzolagan shaxs FISH"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Hujjat imzolagan shaxs FISH"
                                                        v-model="form.t6"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <el-upload
                                                    class="upload-demo"
                                                    action="https://jsonplaceholder.typicode.com/posts/"
                                                    :on-change="handleChange"
                                                    :file-list="fileList"
                                                >
                                                    <el-button
                                                        size="small"
                                                        type="primary"
                                                    >
                                                        <i
                                                            class="
                                                                el-icon-download
                                                            "
                                                        >
                                                        </i>
                                                        Yuklash
                                                    </el-button>
                                                    <div
                                                        slot="tip"
                                                        class="el-upload__tip"
                                                    >
                                                        jpg/png files with a
                                                        size less than 500kb
                                                    </div>
                                                </el-upload>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="add-form-educet">
                                                    <el-button
                                                        class="asosy-btn-d"
                                                        icon="el-icon-plus"
                                                    >
                                                        Qo'shish
                                                    </el-button>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>

                                    <el-tab-pane label="Sertifikarlar">
                                        <el-row :gutter="20">
                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikatlar Nomi"
                                                >
                                                    <el-input
                                                        v-model="form.y1"
                                                        placeholder="Sertifikatlar Nomi"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Qaysi Ixtisosligi Sertifikatlar"
                                                >
                                                    <el-input
                                                        v-model="form.y2"
                                                        placeholder="Qaysi Ixtisosligi Sertifikatlar"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Malaka toifasi qachon olgan"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Malaka toifasi qachon olgan"
                                                        v-model="form.y3"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar qanday xujalar asosida berilgan"
                                                >
                                                    <el-input
                                                        v-model="form.y4"
                                                        placeholder="Sertifikarlar qanday xujalar asosida berilgan"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar seriyasi raqami"
                                                >
                                                    <el-input
                                                        v-model="form.y5"
                                                        placeholder="Sertifikarlar seriyasi raqami "
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar  Sanasi"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Sertifikarlar  Sanasi"
                                                        v-model="form.y6"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar Bergan tashkilot nomi"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Sertifikarlar Bergan tashkilot nomi"
                                                        v-model="form.y7"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar imzolagan shaxs FISH"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Sertifikarlar imzolagan shaxs FISH"
                                                        v-model="form.y7"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <el-upload
                                                    class="upload-demo"
                                                    action="https://jsonplaceholder.typicode.com/posts/"
                                                    :on-change="handleChange"
                                                    :file-list="fileList"
                                                >
                                                    <el-button
                                                        size="small"
                                                        type="primary"
                                                    >
                                                        <i
                                                            class="
                                                                el-icon-download
                                                            "
                                                        >
                                                        </i>
                                                        Yuklash
                                                    </el-button>
                                                    <div
                                                        slot="tip"
                                                        class="el-upload__tip"
                                                    >
                                                        jpg/png files with a
                                                        size less than 500kb
                                                    </div>
                                                </el-upload>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>

                                    <el-tab-pane label="Ilmiy Daraja">
                                        <el-row :gutter="20">
                                            <el-col :span="24">
                                                <el-form-item
                                                    label="Ilmiy daraja bormi"
                                                >
                                                    <el-checkbox-group
                                                        v-model="form.y8"
                                                    >
                                                        <el-checkbox
                                                            label="HA"
                                                            name="type"
                                                        >
                                                        </el-checkbox>
                                                        <el-checkbox
                                                            label="YOQ"
                                                            name="type"
                                                        >
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qnday"
                                                    class="icons-input"
                                                >
                                                    <el-select
                                                        v-model="form.y8"
                                                        placeholder="Ilmiy Daraja qnday"
                                                    >
                                                        <el-option
                                                            label="Fan nomzodi"
                                                            value="shanghai"
                                                        ></el-option>
                                                        <el-option
                                                            label="Fan Doctori"
                                                            value="beijing"
                                                        ></el-option>
                                                        <el-option
                                                            label="Akademik"
                                                            value="beijing2"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qayerda va qanday olingan"
                                                >
                                                    <el-input
                                                        v-model="form.y9"
                                                        placeholder="Ilmiy Daraja qayerda va qanday olingan"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qanday hujatlar bilan imzolangan"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Ilmiy Daraja qanday hujatlar bilan imzolangan"
                                                        v-model="form.y0"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qanday hujatlar bilan imzolangan seriyasi raqami"
                                                >
                                                    <el-input
                                                        v-model="form.nawme"
                                                        placeholder="Ilmiy Daraja qanday hujatlar bilan imzolangan seriyasi raqami "
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <el-upload
                                                    class="upload-demo"
                                                    action="https://jsonplaceholder.typicode.com/posts/"
                                                    :on-change="handleChange"
                                                    :file-list="fileList"
                                                >
                                                    <el-button
                                                        size="small"
                                                        type="primary"
                                                    >
                                                        <i
                                                            class="
                                                                el-icon-download
                                                            "
                                                        >
                                                        </i>
                                                        Yuklash
                                                    </el-button>
                                                    <div
                                                        slot="tip"
                                                        class="el-upload__tip"
                                                    >
                                                        jpg/png files with a
                                                        size less than 500kb
                                                    </div>
                                                </el-upload>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>

                                    <el-tab-pane label="Lavozim">
                                        <el-row :gutter="20">
                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Buyuruq no'meri"
                                                >
                                                    <el-input
                                                        v-model="form.naame"
                                                        placeholder="Buyuruq no'meri"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Buyuruq imolagan shaxs  FISH"
                                                >
                                                    <el-input
                                                        v-model="form.name"
                                                        placeholder="Buyuruq imolagan shaxs  FISH"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Buyuruq imzolagan shaxs Lavozmi"
                                                >
                                                    <el-input
                                                        v-model="form.name"
                                                        placeholder="Buyuruq imzolagan shaxs Lavozmi"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Buyuruq sanasi"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        placeholder="Buyuruq sanasi"
                                                        v-model="form.date1"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qanday hujatlar bilan imzolangan seriyasi raqami"
                                                >
                                                    <el-input
                                                        v-model="form.name"
                                                        placeholder="Ilmiy Daraja qanday hujatlar bilan imzolangan seriyasi raqami "
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>

                                        <el-row :gutter="20">
                                            <el-divider content-position="left">
                                                Boshqa Tibbiy muassasalarda
                                                boshqaruvda ishlaganmi
                                            </el-divider>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Boshqaruvda "
                                                >
                                                    <el-select
                                                        v-model="form.region"
                                                        placeholder="Boshqaruvda"
                                                    >
                                                        <el-option
                                                            label="Ha"
                                                            value="shanghai"
                                                        ></el-option>
                                                        <el-option
                                                            label="Yoq"
                                                            value="beijing"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Qaysi Tashkilot ishlagan"
                                                >
                                                    <el-input
                                                        v-model="form.name"
                                                        placeholder="Qaysi Tashkilot ishlagan "
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Qaysi lavozmida ishlgan"
                                                >
                                                    <el-select
                                                        v-model="form.region"
                                                        placeholder="Qaysi lavozmida ishlgan"
                                                    >
                                                        <el-option
                                                            label="Zone one"
                                                            value="shanghai"
                                                        ></el-option>
                                                        <el-option
                                                            label="Zone two"
                                                            value="beijing"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="add-form-educet">
                                                    <el-button
                                                        class="asosy-btn-d"
                                                        icon="el-icon-plus"
                                                    >
                                                        Qo'shish
                                                    </el-button>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </el-form>
                    </div>
                </div>

                <div class="step-btn">
                    <el-button
                        class="asosy-btn-y"
                        @click="prevStep"
                        icon="el-icon-back"
                    >
                        Oldingi
                    </el-button>
                    <el-button
                        class="asosy-btn-d"
                        @click="nextStep"
                        type="primary"
                        icon="el-icon-right"
                    >
                        Keyingi
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentStep: 1,
            form: {
                type: [],
            },
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            fileList: [
                {
                    name: "food.jpeg",
                    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
                },
            ],
        };
    },
    computed: {
        steps() {
            return [
                {
                    step: 1,
                    stepText: "Qadam 1",
                },
                {
                    step: 2,
                    stepText: "Qadam 2",
                },
                {
                    step: 3,
                    stepText: "Qadam 3",
                },
            ];
        },
    },
    methods: {
        setStep(step) {
            this.currentStep = step;
        },
        prevStep() {
            if (this.currentStep !== 1) {
                this.currentStep--;
            }
        },
        nextStep() {
            if (this.steps.length > this.currentStep) {
                this.currentStep++;
            }
        },
        handleRemove(file) {
            console.log(file);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
    },
};
</script>
